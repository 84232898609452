import React, { useState } from 'react';
import notify from '../../../../../../utils/notify';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import { useMediaQuery } from '@mui/material';
import { postBlog } from '../../../../../../stories/actions/generative';
import analytics from '../../../../../../utils/functions/analytics';
import { SelectChangeEvent } from '@mui/material/Select';

const useForm = (dispatch: any) => {
  const { translate }: TranslateInterface = useLanguage();

  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xl'));

  const [form, setForm] = useState<any>({
    keywords: '',
    audience: '',
    action: '',
    description: '',
    tone: ''
  });

  const [loading, setLoading] = React.useState(false);

  const [output, setOutput] = useState<any>('');

  const [modalOpen, setModalOpen] = useState(false);

  const [random, setRandom] = useState(Math.floor(Math.random() * 2));

  const [modalType, setModalType] = useState(
    random === 0 ? 'post-blog-chatgpt' : 'post-blog'
  );

  const handleSubmit = async (e: any, language: string) => {
    if (e) e.preventDefault();

    if (loading) {
      return;
    }

    if (form.description === '') {
      notify.error(translate('page.postBlog.notify.error.empty.field'));

      return;
    }

    setLoading(true);

    const cb = {
      success: (newOutput: any) => {
        setLoading(false);

        setOutput(newOutput);

        if (isMobile) {
          window.scrollTo(0, 0);
        }
      },
      error: (type?: string) => {
        if (type === 'LIMIT_REACHED') {
          setModalOpen(true);
        }

        if (type === 'TIME_REACHED') {
          setModalOpen(true);
        }

        setLoading(false);
      }
    };

    setTimeout(() => {
      dispatch(postBlog({ ...form, language }, cb));
    }, 0);
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent,
    type: string
  ) => {
    const maxCharacterLimit = 3000;

    const value = e.target.value;

    if (value.length <= maxCharacterLimit) {
      setForm({ ...form, [type]: value });
    }
  };

  const handleReset = () => {
    setLoading(false);

    setForm({
      ...form,
      keywords: '',
      audience: '',
      action: '',
      description: '',
      tone: ''
    });

    setOutput('');
  };

  return {
    output,
    form,
    setForm,
    loading,
    handleSubmit,
    handleChange,
    handleReset,
    modal: {
      open: modalOpen,
      toggle: () => {
        analytics.logEvent('tool-seo', `tool-${modalType}-daily-limit`);

        setModalOpen(!modalOpen);
      },
      type: modalType
    }
  };
};

export default useForm;
