import React, { useContext, useState } from 'react';
import { Box, Grid, TextareaAutosize, useTheme } from '@mui/material';
import useStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import Alert from '../Alerts';
import { GlobalContextInterface } from '../../../../../../utils/contexts/GlobalContext/interface';
import { GlobalContext } from '../../../../../../utils/contexts/GlobalContext';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import useLanguage from '../../../../../../utils/hooks/useLanguage';

type CategoryItemProps = {
  form: any;
  output: any;
  handleChange: any;
  handleReset: () => void;
};

const TextAreas = ({
  form,
  output,
  handleChange,
  handleReset
}: CategoryItemProps) => {
  const classes = useStyle();
  const theme = useTheme();
  const warningLimit = 2000;
  const ctx: GlobalContextInterface = useContext(GlobalContext);
  const { translate }: TranslateInterface = useLanguage();

  const isCharacterLimitReached = form.description.length >= warningLimit;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Box className={classes.stylebox} position="relative">
          <FontAwesomeIcon
            icon={faTrashCan}
            className={classes.trashIcon}
            onClick={handleReset}
          />
          <TextareaAutosize
            minRows={15}
            placeholder={translate(
              'page.commemorativeDate.form.fields.description.placeholder'
            )}
            value={form.description}
            onChange={(e: any): void => handleChange(e, 'description')}
            style={{
              width: '100%',
              border: 'none',
              padding: theme.spacing(2),
              borderRadius: '15px',
              fontSize: '15px',
              fontStyle: 'normal',
              fontFamily: 'Inter',
              fontWeight: '400',
              height: '130px',
              overflow: 'auto',
              lineHeight: '1.5'
            }}
          />
          <div className={classes.characterCounter}>
            {form.description.length}{' '}
            {translate(
              'page.commemorativeDate.form.alert.description.characterCounter'
            )}
          </div>
          <Alert
            message={`${translate(
              'page.commemorativeDate.form.alert.description.counter'
            )} : ${form.description.length}/${warningLimit}`}
            show={isCharacterLimitReached}
          />
        </Box>

        <Box position="relative" className={classes.styleboxTone}>
          <TextareaAutosize
            minRows={1}
            placeholder={translate(
              'page.commemorativeDate.form.fields.tone.placeholder'
            )}
            value={form.tone}
            onChange={(e: any): void => handleChange(e, 'tone')}
            style={{
              width: '100%',
              border: 'none',
              padding: theme.spacing(2),
              borderRadius: '15px',
              fontSize: '15px',
              fontStyle: 'normal',
              fontFamily: 'Inter',
              fontWeight: '400',
              // height: '10px',
              overflow: 'auto',
              lineHeight: '1.5'
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Box
          className={classes.stylebox}
          style={{
            minHeight: '100%'
          }}
        >
          <Box
            style={{
              width: '100%',
              border: 'none',
              height: '250px',
              overflowY: 'auto',
              marginTop: '10px',
              paddingLeft: '12px',
              paddingRight: '12px'
            }}
          >
            {output !== ''
              ? output
              : translate(
                  'page.commemorativeDate.form.fields.result.placeholder'
                )}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default TextAreas;
