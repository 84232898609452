const LANGUAGE_ES: any = {
  configurator: {
    language: {
      options: {
        portuguese: 'Portugués',
        english: 'Inglés',
        spanish: 'Español'
      }
    }
  },
  page: {
    choice: {
      options: {
        comparison: 'Clarice.ai vs ChatGPT',
        rewriteGenerator: 'Reescribir texto',
        textReviewer: 'Revisar texto',
        summarizeText: 'Resumir texto',
        hashtagGenerator: 'Generar hashtag',
        commemorativeDate: 'Fecha Conmemorativa',
        slogan: 'Eslogan',
        aidaStructure: 'Estructura AIDA',
        contentImprover: 'Mejorar contenido',
        emailMarketing: 'Email Marketing',
        instagramPost: 'Publicación de Instagram',
        linkedinPost: 'Publicación de LinkedIn',
        blogPost: 'Publicación de Blog',
        textGenerator: 'Reescribir texto'
      }
    },
    comparison: {
      introduction: {
        title: 'ChatGPT vs Clarice.AI',
        description: 'Descubre la diferencia y selecciona el mejor.'
      },
      steps: {
        one: {
          title: 'Paso 1',
          description: 'Escribe lo que deseas en un campo de texto.'
        },
        two: {
          title: 'Paso 2',
          description: 'Haz clic en el texto que más te guste.'
        },
        three: {
          title: 'Paso 3',
          description:
            '¡Ahora lo sabes! Comparte el resultado en las redes sociales.'
        }
      },
      prompts: {
        one: {
          description:
            'Estoy en el WebSummit descubriendo nuevas startups de tecnología'
        },
        two: {
          description: 'Estoy viajando por Europa'
        },
        three: {
          description:
            'Escribe una publicación de blog sobre inteligencia artificial'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/escribe aquí sobre lo que deseas redactar'
          }
        },
        buttons: {
          submit: 'Generar texto'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un mensaje!'
          },
          default: '¡Algo salió mal!'
        }
      },
      output: {
        version: 'Versión',
        buttons: {
          choose: 'Yo elijo este'
        }
      },
      chosen: {
        ChatGPT: {
          title: '¡Elegiste ChatGPT!',
          description: 'Solo el 10% de los usuarios eligieron ChatGPT.'
        },
        'Clarice.AI': {
          title: '¡Elegiste Clarice.AI!',
          description:
            'No eres el único, más del 90% de los usuarios eligieron Clarice.ai.'
        }
      }
    },
    rewriter: {
      introduction: {
        title: 'Reescribir texto con IA',
        description:
          'La herramienta para reescribir textos de Clarice.ai te permite reescribir palabras, frases y párrafos sin cambiar el significado original de tu texto.'
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Escribe tu texto aquí...',
            placeholderResult: 'Tu texto reescrito aparecerá aquí'
          }
        },
        alert: {
          messages: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Reescribir'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    },
    summarizeText: {
      introduction: {
        title: 'Resumir texto con IA',
        description:
          'La herramienta para resumir textos de Clarice.ai te permite resumir palabras, frases y párrafos sin cambiar el significado original de tu texto.'
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Escribe tu texto aquí...',
            placeholderResult: 'Tu texto resumido aparecerá aquí'
          }
        },
        alert: {
          messages: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Resumir'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    },
    hashtagGenerator: {
      introduction: {
        title: 'Generador de hashtags',
        description: 'Introduce el texto y obtén hashtags.'
      },
      steps: {
        one: {
          title: 'Paso 1',
          description: 'Escribe lo que deseas en un campo de texto.'
        },
        two: {
          title: 'Paso 2',
          description: 'Haz clic en el texto que más te guste.'
        },
        three: {
          title: 'Paso 3',
          description:
            '¡Ahora lo sabes! Comparte el resultado en las redes sociales.'
        }
      },
      prompts: {
        one: {
          description:
            'Estoy en el WebSummit descubriendo nuevas startups de tecnología'
        },
        two: {
          description: 'Estoy viajando por Europa'
        },
        three: {
          description:
            'Escribe una publicación de blog sobre inteligencia artificial'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/escribe aquí tu texto'
          }
        },
        buttons: {
          submit: 'Generar hashtags'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un mensaje!'
          },
          default: '¡Algo salió mal!'
        }
      },
      output: {
        version: 'Versión',
        buttons: {
          choose: 'Yo elijo este'
        }
      },
      chosen: {
        ChatGPT: {
          title: '¡Elegiste ChatGPT!',
          description: 'Solo el 10% de los usuarios eligieron ChatGPT.'
        },
        'Clarice.AI': {
          title: '¡Elegiste Clarice.AI!',
          description:
            'No eres el único, más del 90% de los usuarios eligieron Clarice.ai.'
        }
      }
    },
    commemorativeDate: {
      introduction: {
        title: 'Commemorative Dates',
        description: 'Create creative messages with AI'
      },
      form: {
        fields: {
          description: {
            placeholder: 'Enter the reason for the celebration...'
          },
          tone: {
            placeholder: 'Enter the tone of the message...'
          },
          result: {
            placeholder: 'Fill in the fields and see the magic happen...'
          }
        },
        alert: {
          description: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Generate'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter some text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    contentImprover: {
      introduction: {
        title: 'Text Improver',
        description: 'Improve text online, enhance text with AI'
      },
      form: {
        fields: {
          content: {
            placeholder: 'Enter the content...'
          },
          tone: {
            placeholder: 'Enter the tone of the message...'
          },
          result: {
            placeholder: 'Fill in the fields and see the magic happen...'
          }
        },
        alert: {
          content: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Improve Content'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter some text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    slogan: {
      introduction: {
        title: 'Generador de Eslogan',
        description: 'Crea el eslogan de tu empresa con IA'
      },
      form: {
        fields: {
          name: {
            placeholder: 'Ingresa el nombre de tu negocio o marca...'
          },
          description: {
            placeholder: 'Escribe una breve descripción de tu negocio...'
          },
          words: {
            placeholder: '¿Cuántas palabras deseas en el eslogan?'
          },
          tone: {
            placeholder:
              '¿Cuál es el tono de voz de tu marca? Por ejemplo: ligero e inspirador'
          },
          result: {
            placeholder: 'Completa los campos y deja que ocurra la magia...'
          }
        },
        alert: {
          description: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Generar eslogan'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    },
    aidaStructure: {
      introduction: {
        title: 'Marco AIDA',
        description: 'Crea tu metodología AIDA con IA'
      },
      form: {
        fields: {
          name: {
            placeholder: 'Ingresa el nombre de tu negocio o producto...'
          },
          description: {
            placeholder: 'Escribe una descripción...'
          },
          audience: {
            placeholder: '¿A qué público deseas atraer?'
          },
          action: {
            placeholder: '¿Cuál es la acción esperada?'
          },
          tone: {
            placeholder: 'Indica el tono del mensaje...'
          },
          result: {
            placeholder: 'Completa los campos y deja que ocurra la magia...'
          }
        },
        alert: {
          description: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Generar'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    },
    emailMarketing: {
      introduction: {
        title: 'Email Marketing',
        description: 'Crea tu Email Marketing con IA'
      },
      form: {
        fields: {
          subject: {
            placeholder: 'Ingresa el asunto de tu correo electrónico...'
          },
          description: {
            placeholder: 'Escribe una breve descripción sobre tu campaña...'
          },
          action: {
            placeholder:
              '¿Qué acción esperas que realice tu cliente al recibir el correo?'
          },
          tone: {
            placeholder:
              '¿Cuál es el tono de voz de tu marca? Por ejemplo: ligero e inspirador'
          },
          result: {
            placeholder: 'Rellena los campos y mira la magia suceder...'
          }
        },
        alert: {
          description: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Generar'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    },
    postInstagram: {
      introduction: {
        title: 'Publicación de Instagram',
        description:
          '1 semana de contenido con IA - Ideas de publicaciones para Instagram'
      },
      form: {
        fields: {
          description: {
            placeholder: 'Palabras clave o tema principal'
          },
          sector: {
            placeholder:
              '¿Cuál es el nicho o sector? Ejemplo: IA, moda, fitness'
          },
          audience: {
            placeholder:
              '¿Quiénes son las personas que siguen la marca? Ejemplo: emprendedores'
          },
          tone: {
            placeholder:
              '¿Cuál es el tono de voz de tu marca? Por ejemplo: ligero e inspirador'
          },
          result: {
            placeholder: 'Rellena los campos y deja que ocurra la magia...'
          }
        },
        alert: {
          description: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Generar'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    },
    postLinkedin: {
      introduction: {
        title: 'Publicación de LinkedIn',
        description: 'IA para publicaciones en LinkedIn'
      },
      form: {
        fields: {
          description: {
            placeholder: 'Escribe una breve descripción de tu publicación...'
          },
          tone: {
            placeholder:
              '¿Cuál es el tono de voz de tu marca? Por ejemplo: ligero e inspirador'
          },
          audience: {
            placeholder:
              '¿Cuál es el público objetivo de tu publicación? Por ejemplo: profesionales de marketing'
          },
          action: {
            placeholder:
              '¿Qué acción esperas que tome tu público después de leer la publicación?'
          },
          result: {
            placeholder: 'Rellena los campos y observa la magia suceder...'
          }
        },
        alert: {
          description: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Generar'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    },
    postBlog: {
      introduction: {
        title: 'Publicar Blog',
        description: 'IA para publicaciones de blog'
      },
      form: {
        fields: {
          description: {
            placeholder:
              '¿Sobre qué debería tratar la publicación? Ejemplo: IA en marketing, tendencias tecnológicas, habilidades blandas para líderes'
          },
          tone: {
            placeholder:
              '¿Cuál es el tono de voz de tu marca? Por ejemplo: ligero e inspirador'
          },
          audience: {
            placeholder:
              '¿Quién es el público objetivo de tu publicación? Por ejemplo: profesionales del marketing'
          },
          action: {
            placeholder:
              '¿Qué acción esperas que tome tu público después de leer la publicación?'
          },
          result: {
            placeholder: 'Rellena los campos y mira cómo sucede la magia...'
          }
        },
        alert: {
          description: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Generar'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    },
    textGenerator: {
      introduction: {
        title: 'Generador de Texto',
        description: 'IA para generar texto'
      },
      form: {
        fields: {
          description: {
            placeholder:
              'Escribe una descripción sobre lo que deseas generar...'
          },
          tone: {
            placeholder:
              '¿Cuál es el tono de voz de tu marca? Por ejemplo: ligero e inspirador'
          },
          words: {
            placeholder:
              '¿Cuál es la longitud del texto que deseas generar? Ejemplo: 100 palabras'
          },
          result: {
            placeholder: 'Rellena los campos y mira cómo sucede la magia...'
          }
        },
        alert: {
          description: {
            counter: 'Límite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Generar Texto'
        }
      },
      notify: {
        error: {
          empty: {
            field: '¡Por favor, ingresa un texto!'
          },
          default: '¡Algo salió mal!'
        }
      }
    }
  },
  modal: {
    premium: {
      title: '¡Límite de uso diario alcanzado!',
      description:
        'Prueba Clarice.ai Educación por 7 días gratis. Acceso ilimitado a 6 herramientas de revisión.',
      action: 'Quiero probarlo'
    },
    chatgpt: {
      title: '¡Límite de uso diario alcanzado!',
      description:
        '¡Prueba Clarice.ai Mini gratis por 7 días! Acceso ilimitado a las herramientas esenciales de revisión humanizada.',
      action: '¡Quiero probarlo!'
    }
  },
  route: {
    home: 'SEO - Opções',
    comparison: 'SEO - Clarice.ai vs ChatGPT',
    reviewer: 'SEO - Revisar Texto',
    reWriter: 'SEO - Reescrever Texto',
    summarizeText: 'SEO - Resumir Texto',
    hashtagGenerator: 'SEO - Gerar Hashtag',
    commemorativeDate: 'SEO - Data Comemorativa',
    slogan: 'SEO - Slogan',
    aidaStructure: 'SEO - Estrutura AIDA',
    contentImprover: 'SEO - Melhorar Conteúdo',
    emailMarketing: 'SEO - Email Marketing',
    instagramPost: 'SEO - Publicação de Instagram',
    linkedinPost: 'SEO - Publicação de LinkedIn',
    postBlog: 'SEO - Post Blog',
    textGenerator: 'SEO - Gerador de Texto'
  }
};

export default LANGUAGE_ES;
