import React, { useContext, useState } from 'react';
import { Box, Grid, Container } from '@mui/material';
import useLanguage from '../../../../../../utils/hooks/useLanguage';
import TextArea from '../TextArea';
import Button from '../Button';
import { TranslateInterface } from '../../../../../../utils/interfaces/TranslateInterface';
import { GlobalContextInterface } from '../../../../../../utils/contexts/GlobalContext/interface';
import { GlobalContext } from '../../../../../../utils/contexts/GlobalContext';

interface Props {
  form: any;
  output: any;
  loading: boolean;
  handleChange: any;
  handleSubmit: any;
  handleReset: any;
}

const Form = ({
  form,
  output,
  loading,
  handleChange,
  handleSubmit,
  handleReset
}: Props) => {
  const { translate }: TranslateInterface = useLanguage();
  const ctx: GlobalContextInterface = useContext(GlobalContext);

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextArea
            form={form}
            output={output}
            handleChange={handleChange}
            handleReset={handleReset}
          />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 2 }}>
            <Button
              onClick={(e: any) => handleSubmit(e, ctx.language)}
              loading={loading}
            >
              {translate('page.aidaStructure.form.buttons.submit')}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Form;
