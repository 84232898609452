import instance from '../../utils/axios/instance';
import { emailMarketing } from '../actions/generative';

const GenerativeRequest = {
  generative: async (message: string, language: string) => {
    const { data } = await instance().post(`/generative`, {
      message,
      language
    });

    return data;
  },
  chosen: async (id: string, language: string) => {
    const { data } = await instance().put(
      `/generative/chosen/${language}/${id}`
    );

    return data;
  },
  statistics: async () => {
    const { data } = await instance().get(`/generative`);

    return data;
  },
  rewriter: async (message: string, language: string) => {
    const { data } = await instance().post(`/tools/re-writer`, {
      text: message
    });

    return {
      output: data.result
    };
  },
  hashtagGenerator: async (message: string, language: string) => {
    const { data } = await instance().post(`/tools/hashtag-generator`, {
      text: message
    });

    return {
      output: data.result
    };
  },
  summarizeText: async (message: string, language: string) => {
    const { data } = await instance().post(`/tools/summarize-text`, {
      text: message
    });

    return {
      output: data.result
    };
  },
  commemorativeDate: async (params: any) => {
    const { data } = await instance().post(`/tools/commemorative-date`, {
      description: params.description,
      tone: params.tone
    });

    return {
      output: data.result
    };
  },
  slogan: async (params: any) => {
    const { data } = await instance().post(`/tools/slogan`, {
      name: params.name,
      description: params.description,
      words: params.words,
      tone: params.tone
    });

    return {
      output: data.result
    };
  },
  aidaStructure: async (params: any) => {
    const { data } = await instance().post(`/tools/aida-structure`, {
      name: params.name,
      description: params.description,
      audience: params.audience,
      action: params.action,
      tone: params.tone
    });

    return {
      output: data.result
    };
  },
  contentImprover: async (params: any) => {
    const { data } = await instance().post(`/tools/content-improver`, {
      content: params.content,
      tone: params.tone
    });

    return {
      output: data.result
    };
  },
  emailMarketing: async (params: any) => {
    const { data } = await instance().post(`/tools/email-marketing`, {
      subject: params.subject,
      description: params.description,
      action: params.action,
      tone: params.tone
    });

    return {
      output: data.result
    };
  },
  postInstagram: async (params: any) => {
    const { data } = await instance().post(`/tools/instagram-post`, {
      description: params.description,
      sector: params.sector,
      audience: params.audience,
      tone: params.tone
    });

    return {
      output: data.result
    };
  },
  postLinkedin: async (params: any) => {
    const { data } = await instance().post(`/tools/linkedin-post`, {
      description: params.description,
      tone: params.tone,
      audience: params.audience,
      action: params.action
    });

    return {
      output: data.result
    };
  },
  postBlog: async (params: any) => {
    const { data } = await instance().post(`/tools/blog-post`, {
      description: params.description,
      keywords: params.keywords,
      tone: params.tone,
      audience: params.audience,
      action: params.action
    });

    return {
      output: data.result
    };
  },
  textGenerator: async (params: any) => {
    const { data } = await instance().post(`/tools/text-generator`, {
      description: params.description,
      tone: params.tone,
      words: params.words
    });

    return {
      output: data.result
    };
  }
};

export default GenerativeRequest;
