const LANGUAGE_EN: any = {
  configurator: {
    language: {
      options: {
        portuguese: 'Portuguese',
        english: 'English',
        spanish: 'Spanish'
      }
    }
  },
  page: {
    choice: {
      options: {
        comparison: 'Clarice.ai vs ChatGPT',
        rewriteGenerator: 'Rewrite text',
        textReviewer: 'Review text',
        summarizeText: 'Summarize text',
        hashtagGenerator: 'Generate hashtag',
        commemorativeDate: 'Commemorative Date',
        slogan: 'Slogan',
        aidaStructure: 'AIDA Structure',
        contentImprover: 'Improve content',
        emailMarketing: 'Email Marketing',
        instagramPost: 'Instagram Post',
        linkedinPost: 'Linkedin Post',
        postBlog: 'Blog Post',
        textGenerator: 'Generate text'
      }
    },
    comparison: {
      introduction: {
        title: 'ChatGPT vs Clarice.AI',
        description: 'Discover the difference and select the best.'
      },
      steps: {
        one: {
          title: 'Step 1',
          description: 'Put what you want to write about in a text field.'
        },
        two: {
          title: 'Step 2',
          description: 'Click at the text that you like the most.'
        },
        three: {
          title: 'Step 3',
          description: 'Now you know! Share the result in social media.'
        }
      },
      prompts: {
        one: {
          description: 'I am in WebSummit discovering new tech startups'
        },
        two: {
          description: "I'm traveling around Europe"
        },
        three: {
          description: 'Write a blog post about artificial intelligence'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/put here what you want to write about'
          }
        },
        buttons: {
          submit: 'Generate the text'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter a message!'
          },
          default: 'Something went wrong!'
        }
      },
      output: {
        version: 'Version',
        buttons: {
          choose: 'I choose this'
        }
      },
      chosen: {
        ChatGPT: {
          title: 'You have chosen ChatGPT!',
          description: 'Only 10% of users chose ChatGPT.'
        },
        'Clarice.AI': {
          title: 'You have chosen Clarice.AI!',
          description:
            'You’re not the only one, more than 90% of the users have chosen Clarice.'
        }
      }
    },
    rewriter: {
      introduction: {
        title: 'Rewrite text with AI',
        description:
          "Clarice.ai's text rewriting tool allows you to rewrite words, sentences and paragraphs without changing the original meaning of your text."
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Enter your text here...',
            placeholderResult: 'Your rewritten text will appear here'
          }
        },
        alert: {
          messages: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Rewrite'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    summarizeText: {
      introduction: {
        title: 'RSummarize text with AI',
        description:
          "Clarice.ai's text summarizing tool allows you to summarize words, sentences and paragraphs without changing the original meaning of your text."
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Enter your text here...',
            placeholderResult: 'Your rewritten text will appear here'
          }
        },
        alert: {
          messages: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Summarize'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    hashtagGenerator: {
      introduction: {
        title: 'Hashtag generator',
        description: 'Enter text and get hashtags'
      },
      steps: {
        one: {
          title: 'Step 1',
          description: 'Put what you want to write about in a text field.'
        },
        two: {
          title: 'Step 2',
          description: 'Click at the text that you like the most.'
        },
        three: {
          title: 'Step 3',
          description: 'Now you know! Share the result in social media.'
        }
      },
      prompts: {
        one: {
          description: 'I am in WebSummit discovering new tech startups'
        },
        two: {
          description: "I'm traveling around Europe"
        },
        three: {
          description: 'Write a blog post about artificial intelligence'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/put your text here'
          }
        },
        buttons: {
          submit: 'Generate the hashtags'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter a message!'
          },
          default: 'Something went wrong!'
        }
      },
      output: {
        version: 'Version',
        buttons: {
          choose: 'I choose this'
        }
      },
      chosen: {
        ChatGPT: {
          title: 'You have chosen ChatGPT!',
          description: 'Only 10% of users chose ChatGPT.'
        },
        'Clarice.AI': {
          title: 'You have chosen Clarice.AI!',
          description:
            'You’re not the only one, more than 90% of the users have chosen Clarice.'
        }
      }
    },
    commemorativeDate: {
      introduction: {
        title: 'Commemorative Dates',
        description: 'Create creative messages with AI'
      },
      form: {
        fields: {
          description: {
            placeholder: 'Enter the reason for the celebration...'
          },
          tone: {
            placeholder: 'Enter the tone of the message...'
          },
          result: {
            placeholder: 'Fill in the fields and see the magic happen...'
          }
        },
        alert: {
          description: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Generate'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter some text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    contentImprover: {
      introduction: {
        title: 'Text Improver',
        description: 'Improve text online, enhance text with AI'
      },
      form: {
        fields: {
          content: {
            placeholder: 'Enter the content...'
          },
          tone: {
            placeholder: 'Enter the tone of the message...'
          },
          result: {
            placeholder: 'Fill in the fields and see the magic happen...'
          }
        },
        alert: {
          content: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Improve Content'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter some text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    slogan: {
      introduction: {
        title: 'Slogan Generator',
        description: "Create your company's slogan with AI"
      },
      form: {
        fields: {
          name: {
            placeholder: 'Enter your business or brand name...'
          },
          description: {
            placeholder: 'Enter a brief description of your business...'
          },
          words: {
            placeholder: 'How many words do you want in the slogan?'
          },
          tone: {
            placeholder:
              "What is your brand's tone of voice? For example: light and inspiring"
          },
          result: {
            placeholder: 'Fill in the fields and see the magic happen...'
          }
        },
        alert: {
          description: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Generate slogan'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    aidaStructure: {
      introduction: {
        title: 'AIDA Framework',
        description: 'Create your AIDA Methodology with AI'
      },
      form: {
        fields: {
          name: {
            placeholder: 'Enter your business or product name...'
          },
          description: {
            placeholder: 'Enter a description...'
          },
          audience: {
            placeholder: 'What audience do you want to attract?'
          },
          action: {
            placeholder: 'What is the expected action?'
          },
          tone: {
            placeholder: 'Enter the tone of the message...'
          },
          result: {
            placeholder: 'Fill in the fields and see the magic happen...'
          }
        },
        alert: {
          description: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Generate'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    emailMarketing: {
      introduction: {
        title: 'Email Marketing',
        description: 'Create your Email Marketing with AI'
      },
      form: {
        fields: {
          subject: {
            placeholder: 'Enter your email subject...'
          },
          description: {
            placeholder: 'Enter a brief description of your campaign...'
          },
          action: {
            placeholder:
              'What action do you expect your customer to take upon receiving the email?'
          },
          tone: {
            placeholder:
              'What is your brand’s tone of voice? For example: light and inspiring'
          },
          result: {
            placeholder: 'Fill in the fields and watch the magic happen...'
          }
        },
        alert: {
          description: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Generate'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter some text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    postInstagram: {
      introduction: {
        title: 'Instagram Post',
        description: '1 week of AI-generated content - Instagram post ideas'
      },
      form: {
        fields: {
          description: {
            placeholder: 'Keywords or main topic'
          },
          sector: {
            placeholder:
              'What is the niche or industry? Example: AI, fashion, fitness'
          },
          audience: {
            placeholder:
              'Who are the people following the brand? Example: entrepreneurs'
          },
          tone: {
            placeholder:
              "What is your brand's tone of voice? Example: light and inspiring"
          },
          result: {
            placeholder: 'Fill in the fields and watch the magic happen...'
          }
        },
        alert: {
          description: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Generate'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    postLinkedin: {
      introduction: {
        title: 'LinkedIn Post',
        description: 'AI for LinkedIn posts'
      },
      form: {
        fields: {
          description: {
            placeholder: 'Enter a brief description of your post...'
          },
          tone: {
            placeholder:
              "What is your brand's tone of voice? For example: light and inspiring"
          },
          audience: {
            placeholder:
              'Who is the target audience for your post? For example: marketing professionals'
          },
          action: {
            placeholder:
              'What action do you expect your audience to take after reading the post?'
          },
          result: {
            placeholder: 'Fill in the fields and watch the magic happen...'
          }
        },
        alert: {
          description: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Generate'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    postBlog: {
      introduction: {
        title: 'Post Blog',
        description: 'AI for blog posts'
      },
      form: {
        fields: {
          description: {
            placeholder:
              'What should the post be about? Example: AI in marketing, technology trends, soft skills for leaders'
          },
          tone: {
            placeholder:
              "What is your brand's tone of voice? For example: light and inspiring"
          },
          audience: {
            placeholder:
              'Who is the target audience for your post? For example: marketing professionals'
          },
          action: {
            placeholder:
              'What action do you expect your audience to take after reading the post?'
          },
          result: {
            placeholder: 'Fill in the fields and watch the magic happen...'
          }
        },
        alert: {
          description: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Generate'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter some text!'
          },
          default: 'Something went wrong!'
        }
      }
    },
    textGenerator: {
      introduction: {
        title: 'Text Generator',
        description: 'AI for generating text'
      },
      form: {
        fields: {
          description: {
            placeholder: 'Enter a description of what you want to generate...'
          },
          tone: {
            placeholder:
              "What is your brand's tone of voice? For example: light and inspiring"
          },
          words: {
            placeholder:
              'What is the desired length of the generated text? Example: 100 words'
          },
          result: {
            placeholder: 'Fill in the fields and watch the magic happen...'
          }
        },
        alert: {
          description: {
            counter: 'Character limit exceeded',
            characterCounter: 'Characters'
          }
        },
        buttons: {
          submit: 'Generate Text'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Please enter some text!'
          },
          default: 'Something went wrong!'
        }
      }
    }
  },
  modal: {
    premium: {
      title: 'Daily usage limit reached!',
      description:
        'Try Clarice.ai Education for 7 days free. Unlimited access to 6 revision tools.',
      action: 'I want to try it!'
    },
    chatgpt: {
      title: 'Daily usage limit reached!',
      description:
        'Try Clarice.ai Mini for free for 7 days! Unlimited access to essential humanized review tools!',
      action: 'I want to try it!'
    }
  },
  route: {
    home: 'SEO - Opções',
    comparison: 'SEO - Clarice.ai vs ChatGPT',
    reviewer: 'SEO - Revisar Texto',
    reWriter: 'SEO - Reescrever Texto',
    summarizeText: 'SEO - Resumir Texto',
    hashtagGenerator: 'SEO - Gerar Hashtag',
    commemorativeDate: 'SEO - Data Comemorativa',
    slogan: 'SEO - Slogan',
    aidaStructure: 'SEO - Estrutura AIDA',
    contentImprover: 'SEO - Melhorar Conteúdo',
    emailMarketing: 'SEO - Email Marketing',
    instagramPost: 'SEO - Post Instagram',
    linkedinPost: 'SEO - Post Linkedin',
    postBlog: 'SEO - Post Blog',
    textGenerator: 'SEO - Gerador de Texto'
  }
};

export default LANGUAGE_EN;
