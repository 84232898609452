const LANGUAGE_PT: any = {
  configurator: {
    language: {
      options: {
        portuguese: 'Português',
        english: 'Inglês',
        spanish: 'Espanhol'
      }
    }
  },
  page: {
    choice: {
      options: {
        comparison: 'Clarice.ai vs ChatGPT',
        rewriteGenerator: 'Reescrever texto',
        textReviewer: 'Revisar texto',
        summarizeText: 'Resumir texto',
        hashtagGenerator: 'Gerar hashtag',
        commemorativeDate: 'Data Comemorativa',
        slogan: 'Slogan',
        aidaStructure: 'Estrutura AIDA',
        contentImprover: 'Melhorar Conteúdo',
        emailMarketing: 'Email Marketing',
        postInstagram: 'Post Instagram',
        postLinkedin: 'Post Linkedin',
        postBlog: 'Post Blog',
        textGenerator: 'Gerador de Texto'
      }
    },
    comparison: {
      introduction: {
        title: 'ChatGPT vs Clarice.AI',
        description: 'Descubra a diferença e selecione o melhor.'
      },
      steps: {
        one: {
          title: 'Etapa 1',
          description:
            'Coloque o que você deseja escrever em um campo de texto.'
        },
        two: {
          title: 'Etapa 2',
          description: 'Clique no texto que você mais gosta.'
        },
        three: {
          title: 'Etapa 3',
          description:
            'Agora você sabe! Compartilhe o resultado nas redes sociais.'
        }
      },
      prompts: {
        one: {
          description:
            'Estou no WebSummit descobrindo novas startups de tecnologia'
        },
        two: {
          description: 'Estou viajando pela Europa'
        },
        three: {
          description:
            'Escreva uma postagem de blog sobre inteligência artificial'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/coloque aqui sobre o que você quer escrever'
          }
        },
        buttons: {
          submit: 'Gerar texto'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite uma mensagem!'
          },
          default: 'Algo deu errado!'
        }
      },
      output: {
        version: 'Versão',
        buttons: {
          choose: 'Eu escolho este'
        }
      },
      chosen: {
        ChatGPT: {
          title: 'Você escolheu ChatGPT!',
          description: 'Apenas 10% dos usuários escolheram ChatGPT.'
        },
        'Clarice.AI': {
          title: 'Você escolheu a Clarice.AI!',
          description:
            'Você não é o único, mais de 90% dos usuários escolheram a Clarice.ai.'
        }
      }
    },
    rewriter: {
      introduction: {
        title: 'Reescrever texto com IA',
        description:
          'A ferramenta de reescrever textos da Clarice.ai permite reescrever palavras, frases e parágrafos sem alterar o significado original do seu texto.'
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Digite o seu texto aqui...',
            placeholderResult: 'O seu texto reescrito aparecerá aqui'
          }
        },
        alert: {
          messages: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Reescrever'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    summarizeText: {
      introduction: {
        title: 'Resumir texto com IA',
        description:
          'A ferramenta para resumir textos da Clarice.ai permite resumir palavras, frases e parágrafos sem alterar o significado original do seu texto.'
      },
      form: {
        fields: {
          messages: {
            placeholder: 'Digite o seu texto aqui...',
            placeholderResult: 'O seu texto reescrito aparecerá aqui'
          }
        },
        alert: {
          messages: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Resumir'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    hashtagGenerator: {
      introduction: {
        title: 'Gerador de hashtags',
        description: 'Insira o texto e obtenha hashtags.'
      },
      steps: {
        one: {
          title: 'Etapa 1',
          description:
            'Coloque o que você deseja escrever em um campo de texto.'
        },
        two: {
          title: 'Etapa 2',
          description: 'Clique no texto que você mais gosta.'
        },
        three: {
          title: 'Etapa 3',
          description:
            'Agora você sabe! Compartilhe o resultado nas redes sociais.'
        }
      },
      prompts: {
        one: {
          description:
            'Estou no WebSummit descobrindo novas startups de tecnologia'
        },
        two: {
          description: 'Estou viajando pela Europa'
        },
        three: {
          description:
            'Escreva uma postagem de blog sobre inteligência artificial'
        }
      },
      form: {
        fields: {
          message: {
            placeholder: '/coloque aqui o seu texto'
          }
        },
        buttons: {
          submit: 'Gerar hashtags'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite uma mensagem!'
          },
          default: 'Algo deu errado!'
        }
      },
      output: {
        version: 'Versão',
        buttons: {
          choose: 'Eu escolho este'
        }
      },
      chosen: {
        ChatGPT: {
          title: 'Você escolheu ChatGPT!',
          description: 'Apenas 10% dos usuários escolheram ChatGPT.'
        },
        'Clarice.AI': {
          title: 'Você escolheu a Clarice.AI!',
          description:
            'Você não é o único, mais de 90% dos usuários escolheram a Clarice.ai.'
        }
      }
    },
    commemorativeDate: {
      introduction: {
        title: 'Datas Comemorativas',
        description: 'Crie mensagens criativas com IA'
      },
      form: {
        fields: {
          description: {
            placeholder: 'Informe o motivo da comemoração...'
          },
          tone: {
            placeholder: 'Informe o tom da mensagem...'
          },
          result: {
            placeholder: 'Preencha os campos e veja a mágica acontecer...'
          }
        },
        alert: {
          description: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Gerar'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    contentImprover: {
      introduction: {
        title: 'Melhorar texto ',
        description: 'Melhorar texto online, melhorar texto com ia'
      },
      form: {
        fields: {
          content: {
            placeholder: 'Informe o conteúdo...'
          },
          tone: {
            placeholder: 'Informe o tom da mensagem...'
          },
          result: {
            placeholder: 'Preencha os campos e veja a mágica acontecer...'
          }
        },
        alert: {
          content: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Melhorar conteúdo'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    slogan: {
      introduction: {
        title: 'Gerador de Slogan',
        description: 'Crie o Slogan da sua empresa com IA'
      },
      form: {
        fields: {
          name: {
            placeholder: 'Informe o nome do seu negócio ou da sua marca...'
          },
          description: {
            placeholder: 'Digite uma breve descrição sobre o seu negócio...'
          },
          words: {
            placeholder: 'Qual a quantidade de palavras que deseja no slogan?'
          },
          tone: {
            placeholder:
              'Qual o tom de voz da sua marca? Por exemplo: leve e inspirador'
          },
          result: {
            placeholder: 'Preencha os campos e veja a mágica acontecer...'
          }
        },
        alert: {
          description: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Gerar slogan'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    aidaStructure: {
      introduction: {
        title: 'Aida framework',
        description: 'Crie sua Metodologia AIDA com IA '
      },
      form: {
        fields: {
          name: {
            placeholder: 'Informe o nome do seu negócio ou produto...'
          },
          description: {
            placeholder: 'Digite uma descrição...'
          },
          audience: {
            placeholder: 'Qual público você deseja atrair?'
          },
          action: {
            placeholder: 'Qual ação esperada?'
          },
          tone: {
            placeholder: 'Informe o tom da mensagem...'
          },
          result: {
            placeholder: 'Preencha os campos e veja a mágica acontecer...'
          }
        },
        alert: {
          description: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Gerar'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    emailMarketing: {
      introduction: {
        title: 'Email Marketing',
        description: 'Crie seu Email Marketing com IA'
      },
      form: {
        fields: {
          subject: {
            placeholder: 'Informe o assunto do seu e-mail...'
          },
          description: {
            placeholder: 'Digite uma breve descrição sobre sua campanha...'
          },
          action: {
            placeholder:
              'Qual a ação esperada do seu cliente ao receber o e-mail?'
          },
          tone: {
            placeholder:
              'Qual o tom de voz da sua marca? Por exemplo: leve e inspirador'
          },
          result: {
            placeholder: 'Preencha os campos e veja a mágica acontecer...'
          }
        },
        alert: {
          description: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Gerar'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    postInstagram: {
      introduction: {
        title: 'Post Instagram',
        description:
          '1 semana de conteúdo com IA - Ideias de posts para Instagram'
      },
      form: {
        fields: {
          description: {
            placeholder: 'Palavras-chave ou tópico principal'
          },
          sector: {
            placeholder: 'Qual é o nicho ou setor? Exemplo: IA, moda, fitness'
          },
          audience: {
            placeholder:
              'Quem são as pessoas que seguem a marca? Exemplo: empreendedores'
          },
          tone: {
            placeholder:
              'Qual o tom de voz da sua marca? Por exemplo: leve e inspirador'
          },
          result: {
            placeholder: 'Preencha os campos e veja a mágica acontecer...'
          }
        },
        alert: {
          description: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Gerar'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    postLinkedin: {
      introduction: {
        title: 'Post Linkedin',
        description: 'IA para posts no Linkedin'
      },
      form: {
        fields: {
          description: {
            placeholder:
              'Sobre o que o artigo deve falar? Exemplo: IA no marketing, tendências de tecnologia, soft skills para líderes'
          },
          tone: {
            placeholder:
              'Qual o tom de voz da sua marca? Por exemplo: leve e inspirador'
          },
          audience: {
            placeholder:
              'Qual o público-alvo do seu post? Por exemplo: profissionais de marketing'
          },
          action: {
            placeholder: 'Qual a ação esperada do seu público ao ler o post?'
          },
          result: {
            placeholder: 'Preencha os campos e veja a mágica acontecer...'
          }
        },
        alert: {
          description: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Gerar'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    postBlog: {
      introduction: {
        title: 'Post Blog',
        description: 'IA para posts de blog'
      },
      form: {
        fields: {
          description: {
            placeholder:
              'Sobre o que post deve falar? Exemplo: IA no marketing, tendências de tecnologia, soft skills para líderes'
          },
          keywords: {
            placeholder: 'Qual palavra-chave será trabalhada em SEO?'
          },
          tone: {
            placeholder:
              'Qual o tom de voz da sua marca? Por exemplo: leve e inspirador'
          },
          audience: {
            placeholder:
              'Qual o público-alvo do seu post? Por exemplo: profissionais de marketing'
          },
          action: {
            placeholder: 'Qual a ação esperada do seu público ao ler o post?'
          },
          result: {
            placeholder: 'Preencha os campos e veja a mágica acontecer...'
          }
        },
        alert: {
          description: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Gerar'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    },
    textGenerator: {
      introduction: {
        title: 'Text Generator',
        description: 'IA para gerar texto'
      },
      form: {
        fields: {
          description: {
            placeholder: 'Digite uma descrição sobre o que você deseja gerar...'
          },
          tone: {
            placeholder: 'Qual o tom de voz da mensagem?'
          },
          words: {
            placeholder: 'Qual a quantidade de palavras do seu texto?'
          },
          result: {
            placeholder: 'Preencha os campos e veja a mágica acontecer...'
          }
        },
        alert: {
          description: {
            counter: 'Limite de caracteres excedido',
            characterCounter: 'Caracteres'
          }
        },
        buttons: {
          submit: 'Gerar texto'
        }
      },
      notify: {
        error: {
          empty: {
            field: 'Por favor, digite um texto!'
          },
          default: 'Algo deu errado!'
        }
      }
    }
  },
  modal: {
    premium: {
      title: 'Limite de uso diário atingido!',
      description:
        'Teste a Clarice.ai Educação por 7 dias grátis. Acesso ilimitado a 6 ferramentas de revisão.',
      action: 'Quero experimentar!'
    },
    chatgpt: {
      title: 'Limite de uso diário atingido!',
      description:
        'Teste Clarice.ai Mini por 7 dias grátis! Acesso ilimitado às ferramentas essenciais de revisão humanizada!',
      action: 'Quero experimentar!'
    }
  },
  route: {
    home: 'SEO - Ferramentas',
    comparison: 'SEO - Clarice.ai vs ChatGPT',
    reviewer: 'SEO - Revisar Texto',
    reWriter: 'SEO - Reescrever Texto',
    summarizeText: 'SEO - Resumir Texto',
    hashtagGenerator: 'SEO - Gerador Hashtag',
    commemorativeDate: 'SEO - Data Comemorativa',
    slogan: 'SEO - Slogan',
    aidaStructure: 'SEO - Estrutura AIDA',
    contentImprover: 'SEO - Melhorar Conteúdo',
    emailMarketing: 'SEO - Email Marketing',
    postInstagram: 'SEO - Post Instagram',
    postLinkedin: 'SEO - Post Linkedin',
    postBlog: 'SEO - Post Blog',
    textGenerator: 'SEO - Gerador de Texto'
  }
};

export default LANGUAGE_PT;
