import { RouteType } from './interface/config';
import ChoicePage from '../../../../layouts/App/pages/choice';
import RewriterPage from '../../../../layouts/App/pages/reWriter';
import HashtagGeneratorPage from '../../../../layouts/App/pages/hashtagGenerator';
import ComparisonPage from '../../../../layouts/App/pages/comparison';
import ReviewerPage from '../../../../layouts/App/pages/reviewer';
import { EditorContextProvider } from '../../../contexts/EditorContext';
import SummarizePage from '../../../../layouts/App/pages/summarize';
import CommemorativeDatePage from '../../../../layouts/App/pages/commemorativeDate';
import ContentImproverPage from '../../../../layouts/App/pages/contentImprover';
import SloganPage from '../../../../layouts/App/pages/slogan';
import AidaStructurePage from '../../../../layouts/App/pages/aidaStructure';
import EmailMarketingPage from '../../../../layouts/App/pages/emailMarketing';
import PostInstagramPage from '../../../../layouts/App/pages/postInstagram';
import PostLinkedinPage from '../../../../layouts/App/pages/postLinkedin';
import PostBlogPage from '../../../../layouts/App/pages/postBlog';
import TextGeneratorPage from '../../../../layouts/App/pages/textGenerator';

const routes: RouteType[] = [
  {
    path: '/',
    element: <ChoicePage />,
    state: 'choice',
    protected: false
  },
  {
    path: '/comparison',
    element: <ComparisonPage />,
    state: 'comparison',
    protected: false,
    child: [
      {
        path: ':code',
        element: <ComparisonPage />,
        state: 'comparison',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/reviewer',
    element: (
      <EditorContextProvider>
        <ReviewerPage />
      </EditorContextProvider>
    ),
    state: 'reviewer',
    protected: false,
    child: [
      {
        path: ':code',
        element: (
          <EditorContextProvider>
            <ReviewerPage />
          </EditorContextProvider>
        ),
        state: 'reviewer',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/re-writer',
    element: <RewriterPage />,
    state: 're-writer',
    protected: false,
    child: [
      {
        path: ':code',
        element: <RewriterPage />,
        state: 're-writer',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/summarize-text',
    element: <SummarizePage />,
    state: 'summarize-text',
    protected: false,
    child: [
      {
        path: ':code',
        element: <SummarizePage />,
        state: 'summarize-text',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/hashtag-generator',
    element: <HashtagGeneratorPage />,
    state: 'hashtag-generator',
    protected: false,
    child: [
      {
        path: ':code',
        element: <HashtagGeneratorPage />,
        state: 'hashtag-generator',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/commemorative-date',
    element: <CommemorativeDatePage />,
    state: 'commemorative-date',
    protected: false,
    child: [
      {
        path: ':code',
        element: <CommemorativeDatePage />,
        state: 'commemorative-date',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/slogan',
    element: <SloganPage />,
    state: 'slogan',
    protected: false,
    child: [
      {
        path: ':code',
        element: <SloganPage />,
        state: 'slogan',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/aida-structure',
    element: <AidaStructurePage />,
    state: 'aida-structure',
    protected: false,
    child: [
      {
        path: ':code',
        element: <AidaStructurePage />,
        state: 'aida-structure',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/content-improver',
    element: <ContentImproverPage />,
    state: 'content-improver',
    protected: false,
    child: [
      {
        path: ':code',
        element: <ContentImproverPage />,
        state: 'content-improver',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/email-marketing',
    element: <EmailMarketingPage />,
    state: 'email-marketing',
    protected: false,
    child: [
      {
        path: ':code',
        element: <EmailMarketingPage />,
        state: 'email-marketing',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/post-instagram',
    element: <PostInstagramPage />,
    state: 'post-instagram',
    protected: false,
    child: [
      {
        path: ':code',
        element: <PostInstagramPage />,
        state: 'post-instagram',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/post-linkedin',
    element: <PostLinkedinPage />,
    state: 'post-linkedin',
    protected: false,
    child: [
      {
        path: ':code',
        element: <PostLinkedinPage />,
        state: 'post-linkedin',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/post-blog',
    element: <PostBlogPage />,
    state: 'post-blog',
    protected: false,
    child: [
      {
        path: ':code',
        element: <PostBlogPage />,
        state: 'post-blog',
        protected: false,
        sidebar: 0
      }
    ]
  },
  {
    path: '/text-generator',
    element: <TextGeneratorPage />,
    state: 'text-generator',
    protected: false,
    child: [
      {
        path: ':code',
        element: <TextGeneratorPage />,
        state: 'text-generator',
        protected: false,
        sidebar: 0
      }
    ]
  }
];

export default routes;
