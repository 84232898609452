import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

const useStyles = makeStyles((theme: Theme) => ({
  stylebox: {
    backgroundColor: theme.palette.background.paper,
    minHeight: '75%%',
    border: '1px solid rgba(107,4,255,0.3)',
    borderRadius: '15px',
    // padding: theme.spacing(2),
    // padding: '36px 15px 28px 15px',
    position: 'relative',
    width: '100%',
    color: '#4A4A4A',
    fontSize: '15px'
  },
  styleboxName: {
    marginBottom: '20px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(107,4,255,0.3)',
    borderRadius: '15px',
    // padding: theme.spacing(2),
    // padding: '36px 15px 28px 15px',
    position: 'relative',
    color: '#4A4A4A',
    fontSize: '15px'
  },
  styleboxAction: {
    marginTop: '20px',
    marginBottom: '20px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(107,4,255,0.3)',
    borderRadius: '15px',
    // padding: theme.spacing(2),
    // padding: '36px 15px 28px 15px',
    position: 'relative',
    color: '#4A4A4A',
    fontSize: '15px'
  },
  styleboxTone: {
    marginTop: '20px',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid rgba(107,4,255,0.3)',
    borderRadius: '15px',
    // padding: theme.spacing(2),
    // padding: '36px 15px 28px 15px',
    position: 'relative',
    color: '#4A4A4A',
    fontSize: '15px'
  },
  styleboxWords: {
    marginTop: '25px'
  },
  trashIcon: {
    position: 'absolute',
    top: theme.spacing(1.2),
    right: theme.spacing(1.2),
    cursor: 'pointer',
    '&:active': {
      color: theme.palette.error.main
    },
    color: '#9d9d9d'
  },
  characterCounter: {
    position: 'absolute',
    bottom: theme.spacing(1),
    left: theme.spacing(1.2),
    color: '#9d9d9d',
    fontSize: '0.875rem'
  }
}));

export default useStyles;
